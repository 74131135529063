<template>
	<div class="home">
		主页
		
	</div>
</template>

<script>

	export default {
		name: 'Home',
		components: {
			// HelloWorld
		},
		computed: {
			// key() {
			// 	return this.$route.path
			// }
		},
		data() {
			return {
				nav: {

				},
				show: {
					
				},
				tid:'',
				username:''
			}
		},
		created() {
			this.$router.push('/login')
			
			
		},
		methods: {
			
		}
	}
</script>

<style lang="scss" scoped="scoped">
	
</style>
