<template>
	<div class="page">
		<div class="box">
			<div class="mb20 textCenter"><b>第一步：请将用户名/密钥 复制到谷歌验证器中 或者 扫码下图二维码</b></div>
			<div class="box1">
				<div class="item flexX flexBetween flexcenter"><span><b>用户名:</b></span><span>{{googleData.username}}</span></div>
				<div class="item flexX flexBetween flexcenter"><span><b>密钥:</b></span><span>{{googleData.secret}}</span></div>
				<div class="item flexX flexBetween flexcenter"><span><b>密钥二维码:</b></span><img :src="googleData.qrCodeUrl" style="width: 100px;"></div>
			</div>
			<div class="mb20 mt20 textCenter"><b>第二步：输入谷歌验证器中的验证码</b></div>
			<div class="box1">
				<div class="flexX flexBetween flexcenter">
					<span><b>验证码:</b></span>
					<el-input style="width: 200px;" prefix-icon="el-icon-key" placeholder="请输入谷歌验证码" v-model="code" clearable></el-input>
				</div>
			</div>
			<div class="mt20"><el-button type="primary" style="width: 100%;" @click="bingGoogle()">绑定密钥</el-button></div>
		</div>
	</div>
</template>

<script>
	import local from '../../util/local.js'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isAllNumber
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				labelPosition:'right',
				googleData:{
					username:'',
					secret:'',
					qrCodeUrl:'',
				},
				code:''
			}
		},
		created() {
			this.googleData = local.get('googleInfo')
		},
		methods: {
			bingGoogle(){
				if(isEmpty(this.code,'请输入谷歌验证码')){
					return
				}
				let data = {
					username:this.googleData.username,
					code:this.code
				}
				this.$api.bingGoogle(data).then(res=>{
					if(res.status == 1){
						this.$message({
						  type: 'success',
						  message: res.msg
						});   
						this.$router.push({path:"/login"})
					}else{
						this.$message({
						  type: 'error',
						  message: res.msg
						});   
					}
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	@import '@/style/variables.scss';
	.page{
		background-color: $pageColor;
		width: 100vw;
		height: 100vh;
		box-sizing: border-box;
		padding: 20px;
	}
	.box{
		width: 500px;
		margin: 0 auto;
		.box1{
			
			background-color: #fff;
			padding: 20px;
			width: 100%;
			.item{
				border-bottom: solid 1px $tableBorderColor;
				padding: 10px 0;
			}
		}
		
	}
</style>
