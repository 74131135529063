<template>
	<div>
		<!-- 提润订单 -->
		<div class="queryBox">
			<!-- <div class="mb20"> -->
				<!-- <el-input size="mini" placeholder="请输入订单号" v-model="order_id" clearable class="mr20" style="width: 200px"></el-input> -->
				<!-- <el-input size="mini" placeholder="请输入交易单号" v-model="water_no" clearable class="mr20" style="width: 200px"></el-input> -->
				<!-- <el-input size="mini" placeholder="请输子订单银行卡号" v-model="childCard" clearable class="mr20" style="width: 200px"></el-input> -->
				<!-- <el-input size="mini" placeholder="请输子订单银行姓名" v-model="childName" clearable class="mr20" style="width: 200px"></el-input> -->
				
				<!-- <el-input size="mini" placeholder="请输入商家号" v-model="business_no" clearable class="mr20" style="width: 200px"></el-input> -->

			<!-- </div> -->
			<div class="mb20">
				<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
				<el-date-picker size="mini" v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
				 end-placeholder="结束日期" class="mr20"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>


				<el-select v-model="state" placeholder="请选择状态" size="mini" class="mr20" clearable>
					<el-option v-for="(item,index) in stateArr" :key="index" :label="item" :value="index"></el-option>
				</el-select>
				
				<!-- <el-input size="mini" placeholder="请输子订单银行卡号" v-model="childCard" clearable class="mr20" style="width: 200px"></el-input> -->
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData"
				 :disabled="disLoading">查询</el-button>
				<el-button size="mini" type="primary" icon="el-icon-document" @click="getExcel" :disabled="tableData.length == 0">导出Excel</el-button>
			</div>
		</div>
		<template>
			<el-table :data="tableData" style="width: 1000px" border size="mini" v-loading="loading">
				<el-table-column label="平台交易号" prop="water_no" align="center" min-width="150px">
					<template slot-scope="scope">
						<span v-if="scope.$index < tableData.length-2">{{scope.row.water_no}}</span>
						<span v-if="scope.$index == tableData.length-2">当页总计</span>
						<span v-if="scope.$index == tableData.length-1">总计</span>
					</template>
				</el-table-column>
				<el-table-column label="金额" align="center">
					<template slot-scope="scope">
						{{scope.row.gold}}
					</template>
				</el-table-column>
				<!-- <el-table-column label="提成" align="center">
					<template slot-scope="scope">
						
						<p>{{scope.row.fee_gold_ag}}</p>
					</template>
				</el-table-column> -->
				<el-table-column label="银行信息" align="center" width="200px">
					<template slot-scope="props">
						<div v-if="props.$index < tableData.length-2">
							{{props.row.bank}}
							<br>
							{{props.row.name}}
							<br>
							{{props.row.card}}
						</div>
					</template>
				</el-table-column>
				<!-- </el-table-column> -->
				<el-table-column label="提交时间" prop="add_time" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.add_time">
							{{scope.row.add_time.split(" ")[0]}}
							<br>
							{{scope.row.add_time.split(" ")[1]}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="处理时间" prop="action_time" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.action_time">
							{{scope.row.action_time.split(" ")[0]}}
							<br>
							{{scope.row.action_time.split(" ")[1]}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="订单状态" align="center">
					<template slot-scope="props">
						<span :class="props.row.state== 1?'csuccess':'cdanger'">{{props.row.state_name}}</span>
					</template>
				</el-table-column>
			</el-table>
		</template>

		<div class="flexX  mt20">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	import local from '@/util/local'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				expends: [],
				tableData: [],
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				order_id: '',
				water_no: '',
				business_no: '',
				card_name: '',
				business_name: '',
				add_gold: '',
				req_ip: '',
				pay_type: '',
				state: '',
				paywayArr: [],
				stateArr: {},
				// stateMap:{},
				date: null,
				countAll: {},
				diapageSizesArr: [20, 50, 100, 200],
				diapageSize: 20,
				diatotal: 0,
				diapage: 1,
				nodepageSizesArr: [20, 50, 100, 200],
				nodepageSize: 20,
				nodetotal: 0,
				nodepage: 1,
				outpageSizesArr: [20, 50, 100, 200],
				outpageSize: 20,
				outtotal: 0,
				outpage: 1,
				billpageSizesArr: [20, 50, 100, 200],
				billpageSize: 20,
				billtotal: 0,
				billpage: 1,
				dialogVisible: false,
				dialogloading: false,
				item: {},
				infoTotalData: {}, //搬砖工
				cardListData: [], //银行卡列表
				cardNoteData: [], //银行卡记录  流水
				cardNoteOutData: [], //卡出账记录 
				cardBillData: [], //卡账单
				bill_update_time: '', //卡账单 最后更新时间
				activeName: 'info',
				//冲正
				dialogVisible2: false,
				note: '',
				//审核
				reviewVisible: false,
				reviewloading: false,
				dataIndex: '',
				pIndex: '',
				sIndex: '',
				rushType: '',
				cardListdate: null,
				cardListdisLoading: false,
				//手动派卡
				manualVisible: false,
				manualQueryloading: false,
				manualloading: false,
				manualTableData: [],
				manualTableDataChoose: [],
				manualpageSizesArr: [20, 50, 100, 200],
				manualpageSize: 20,
				manualtotal: 0,
				manualpage: 1,
				canAdd: false,
				canCancle: false,
				manualCarder_username: '',
				manualtype: '', //main 主订单   child子订单
				manualCard: '',
				manualCard_name: '',
				manualMin_gold: '',
				manualMax_gold: '',
				//右侧
				manualRightpageSizesArr: [20, 50, 100, 200],
				manualRightpageSize: 20,
				manualRighttotal: 0,
				manualRightpage: 1,
				//按子订单银行卡号/姓名搜索
				childCard:'',
				childName:'',
			};
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),
				true)];
			this.cardListdate = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),
				true)]

			// this.paywayAll()
			if (this.$route.query.state) {
				this.state = this.$route.query.state;
				this.date = null
			}
			this.stateList()
			this.orderOutList()
			// this.orderCountAll()

		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// console.log('sssssssssss', (value * 1).toFixed(2) + '')
					return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate, newDate]
				this.getData()
			},
			cardListsetDate(day) {
				let data = this.cardListdate ? this.cardListdate[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.cardListdate = [newDate, newDate]
				this.cardListgetData()
				// console.log(this.date)
			},
			cardListgetData() {
				this.diapage = 1;
				this.cardList()
			},
			getTimeDifference(startTime, endTime) {
				return this.$util.intervalTime(startTime, endTime)
			},
			//设置table中的扩展项，展开的id，此处我需要全部展开
			getExpends() {
				let tableData = this.tableData.filter(item => {
					if (item.id) {
						return item
					}
				})
				var Id = tableData.map(item => {
					return item.id
				})
				this.expends = Id
				// console.log(this.expends)
			},
			getRowKeys(row) {
				return row.id
			},
			//搜索按钮
			getData() {
				this.page = 1;
				this.orderOutList();
			},
			//总计
			orderCountAll() {
				this.$api.orderCountAll({}).then(res => {
					// console.log(res)
					if (res.status == 1) {
						this.countAll = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},

			getRowClassName({
				row,
				rowIndex
			}) {
				if (!row.id) {
					return 'row-expand-cover';
				}
			},

			//导出Excel
			getExcel() {
				//manager/order/exportList
				// console.log(this.$baseURL)
				// let data = {
				let username = local.get('dlusername');
				let tid = local.get('dltid')
				let start_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '';
				let end_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '';
				// }
				let href =
					`${this.$baseURL}/agent/order/exportOutList?username=${username}&tid=${tid}&order_id=${this.order_id}&water_no=${this.water_no}&business_no=${this.business_no}&card_name=${this.card_name}&business_name=${this.business_name}&add_gold=${this.add_gold}&req_ip=${this.req_ip}&pay_type=${this.pay_type}&state=${this.state}&start_time=${start_time}&end_time=${end_time}&type=5`
				// console.log(href)
				window.location.href = href
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
				this.orderOutList()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.orderOutList()
			},
			diahandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.diapage = 1
				this.diapageSize = val
				this.cardList()
			},
			diahandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.diapage = val
				this.cardList()
			},

			nodehandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.nodepage = 1
				this.nodepageSize = val
				this.moneyList()
			},
			nodehandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.nodepage = val
				this.moneyList()
			},
			outhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.outpage = 1
				this.outpageSize = val
				this.outList()
			},
			outhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.outpage = val
				this.outList()
			},
			billhandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.billpage = 1
				this.billpageSize = val
				this.cardBill()
			},
			billhandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.billpage = val
				this.cardBill()
			},
			paywayAll() {
				this.$api.paywayAll({}).then(res => {
					if (res.status === 1) {
						this.paywayArr = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			stateList() {
				this.$api.outStateList({}).then(res => {
					if (res.status === 1) {
						this.stateArr = res.data
						// this.stateArr.forEach(item=>{
						// 	this.stateMap[item.id] = item.note
						// })
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			orderOutList() {
				let data = {
					type: "1,2",
					order_id: this.order_id,
					water_no: this.water_no,
					business_no: this.business_no,
					business_name: this.business_name,
					state: this.state,
					start_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '',
					end_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '',
					page: this.page,
					size: this.pageSize,
					card:this.childCard,
					card_name:this.childName,
				}
				// this.loading = true
				this.disLoading = true
				this.$api.orderOutList(data).then(res => {
					this.loading = false;
					this.disLoading = false;
					if (res.status === 1) {
						this.tableData = res.data.data;
						this.total = res.data.total;
						this.getExpends();
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.disLoading = false
					this.loading = false
				})
			},
			gotopage() {
				this.$router.push({
					path: '/orderInfo'
				})
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return 'success-rowT';
				} else {
					return 'success-row';
				}
				// return '';
			},
			//查看代理
			queryAgent(id) {
				// this.dialogloading = true
				this.$api.showAgent({
					carder_id: id
				}).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						let agent = res.data.join(' -> ')
						this.$alert('上级代理为:' + agent, '上级代理', {
							confirmButtonText: '确定',
						});
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}

				}).catch(error => {
					this.dialogloading = false
				})

			},
			showInfo(item) {
				// this.$router.push({path:'/orderInfo'})
				this.dialogVisible = true;
				this.item = item
				this.infoTotal(item)
			},
			getInfoData(val) {
				if (val.name == 'info') {
					if (JSON.stringify(this.infoTotalData) == '{}') {
						this.infoTotal()
					}
				}
				if (val.name == 'bankList') {
					if (this.cardListData.length == 0) {
						this.cardList()
					}
				}
				if (val.name == 'cardNote') {
					if (this.cardNoteData.length == 0) {
						this.moneyList()
					}
				}
				if (val.name == 'cardNoteOut') {
					if (this.cardNoteOutData.length == 0) {
						this.outList()
					}
				}
				if (val.name == 'cardBill') {
					if (this.cardBillData.length == 0) {
						this.cardBill()
					}
				}
				// console.log('val', val)
			},
			infoTotal(item) {
				let data = {
					id: item.bank_card_id,
					type: 2
				}
				// this.dialogloading = true
				this.$api.infoTotal(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.infoTotalData = res.data
					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			cardList(item) {
				let data = {
					carder_id: this.infoTotalData.id,
					size: this.diapageSize,
					page: this.diapage,
					// type:2
					startdate: this.cardListdate ? this.cardListdate[0] : '',
					enddate: this.cardListdate ? this.cardListdate[1] : '',
				}

				// this.dialogloading = true
				this.cardListdisLoading = true
				this.$api.cardList(data).then(res => {
					this.dialogloading = false
					this.cardListdisLoading = false
					if (res.status == 1) {
						this.cardListData = res.data.data
						this.diatotal = res.data.total

					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
					this.cardListdisLoading = false
				})
			},
			close() {
				this.dialogVisible = false
				this.item = {}
				this.infoTotalData = {} //搬砖工
				this.cardListData = [] //银行卡列表
				this.cardNoteData = []
				this.cardNoteOutData = []
				this.cardBillData = []
				this.activeName = 'info'
				this.diapage = 1
				this.nodepage = 1
				this.outpage = 1
				this.billpage = 1
				
				this.cardListdate = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),
					true)]

			},
			close2() {
				this.dialogVisible2 = false
				this.note = ""

			},
			moneyList() {
				let data = {
					page: this.nodepage,
					size: this.nodepageSize,
					id: this.item.bank_card_id,
				}
				// this.dialogloading = true
				this.$api.moneyList(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardNoteData = res.data.data
						this.nodetotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			outList() {
				let data = {
					page: this.outpage,
					size: this.outpageSize,
					id: this.item.bank_card_id,
				}
				// this.dialogloading = true
				this.$api.outList(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardNoteOutData = res.data.data
						this.outtotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			//卡账单
			cardBill() {
				let data = {
					page: this.billpage,
					size: this.billpageSize,
					id: this.item.bank_card_id,
				}
				// this.dialogloading = true
				this.$api.cardBill(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.cardBillData = res.data.data
						this.billtotal = res.data.total
						this.bill_update_time = res.data.bill_update_time
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			cancle(item, type, index, sindex) {
				this.rushType = type
				this.dialogVisible2 = true;
				this.item = item;
				this.pIndex = index;
				this.sIndex = sindex;

			},
			suerOrderRush() {
				if (this.rushType == "child") {
					this.rushCard();
				}
				if (this.rushType == "parent") {
					this.orderRush()
				}
				if (this.rushType == "budan") {
					//补单
					this.repayReplenishment()
				}
			},
			rushCard() {
				this.$confirm('确认冲正订单' + this.item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: this.item.id,
						note: this.note
					}
					this.dialogloading = true
					this.$api.rushCard(data).then(res => {
						this.dialogloading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {
							this.close2()
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.dialogloading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消冲正订单'
					});
				});
			},
			orderRush() {
				this.$confirm('确认冲正订单' + this.item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: this.item.id,
						note: this.note
					}
					this.dialogloading = true
					this.$api.orderRush(data).then(res => {
						this.dialogloading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {
							this.close2();
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.dialogloading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消冲正订单'
					});
				});
			},
			suerOrder(item, pIndex, sIndex) {
				this.pIndex = pIndex
				this.sIndex = sIndex
				this.$confirm('确认订单' + item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.ensureOrder(data).then(res => {
						this.loading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {
							let nowTime = this.$util.timestampToTime(new Date().getTime());
							let username = local.get("username")

							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消确认订单'
					});
				});
			},
			ensureMain(item, pIndex) {
				this.pIndex = pIndex
				this.$confirm('确认订单' + item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					// return
					this.$api.ensureMain(data).then(res => {
						this.loading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消确认订单'
					});
				});
			},
			//关闭主订单
			closeMainOrder(item, pIndex) {
				this.pIndex = pIndex
				this.$confirm('确认关闭订单' + item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.closeMainOrder(data).then(res => {
						this.loading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {

							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消关闭订单'
					});
				});
			},
			//关闭子订单
			closeOrder(item, pIndex, sIndex) {
				this.pIndex = pIndex
				this.sIndex = sIndex
				this.$confirm('确认关闭订单' + item.order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.closeOrder(data).then(res => {
						this.loading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {

							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消关闭订单'
					});
				});
			},
			//重新派卡
			disCard(item, pIndex, sIndex) {
				this.pIndex = pIndex
				this.sIndex = sIndex
				this.$confirm('确认订单' + item.order_id + '重新派卡吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id
					}
					this.loading = true
					this.$api.disCard(data).then(res => {
						this.loading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消订单重新派卡'
					});
				});

			},
			//审核按钮
			review(item, index) {
				this.dataIndex = index
				console.log(this.dataIndex)
				this.item = item
				this.reviewVisible = true
			},
			reviewClose() {
				this.reviewVisible = false
				this.item = {}
			},
			reviewPass() {
				this.$confirm('确认通过审核吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//审核通过
					let data = {
						id: this.item.id,
						type: 1
					}
					this.reviewloading = true;
					// this.tableData[this.dataIndex].state = 2

					this.checkMentionOrder(data, 1)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消审核'
					});
				});
			},
			reviewCloseOrder() {
				this.$confirm('确认关闭审核吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//审核关闭
					let data = {
						id: this.item.id,
						type: 2
					}
					this.reviewloading = true;
					this.checkMentionOrder(data, 2)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消关闭审核'
					});
				});
			},
			checkMentionOrder(data, type) {
				this.$api.checkMentionOrder(data).then(res => {
					this.reviewloading = false;
					this.$api.outInfo({
						id: this.item.id
					}).then(subRes => {
						if (subRes.status == 1) {
							this.tableData.splice(this.pIndex, 1, subRes.data)
						} else {
							this.$message({
								type: 'error',
								message: subRes.msg
							})
						}
					})
					if (res.status == 1) {
						this.reviewClose()
						let nowTime = this.$util.timestampToTime(new Date().getTime());
						let username = local.get("username")

						this.$message({
							type: "success",
							message: res.msg
						})
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.$api.outInfo({
						id: this.item.id
					}).then(subRes => {
						if (subRes.status == 1) {
							this.tableData.splice(this.pIndex, 1, subRes.data)
						} else {
							this.$message({
								type: 'error',
								message: subRes.msg
							})
						}
					})
					this.reviewloading = false;
				})
			},
			repayReplenishment() {
				this.$confirm('确认补单' + this.tableData[this.pIndex].cardItem[this.sIndex].card_order_id + '吗?', '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: this.tableData[this.pIndex].cardItem[this.sIndex].id,
						note: this.note
					}
					this.dialogloading = true
					this.$api.repayReplenishment(data).then(res => {
						this.dialogloading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
						if (res.status == 1) {
							this.close2()
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消补单'
					});
				});
			},
			//手动选卡
			showChooseCard(item, pIndex, sIndex) {
				if (sIndex || sIndex == 0 || sIndex == '0') {
					//子订单
					this.sIndex = sIndex;
					this.manualtype = 'child'
				} else {
					this.manualtype = 'main'
				}
				console.log(sIndex, this.manualtype)
				this.manualVisible = true
				this.item = item;
				this.pIndex = pIndex;
				this.manualDisCardList()
				this.choosedDisCardList()
			},
			//手动选卡 查询按钮

			manualgetData() {
				if (this.manualMin_gold != '' && this.manualMin_gold != '0' && this.manualMin_gold != 0) {
					if (!isMoney(this.manualMin_gold, "输入的最小金额不合法")) {
						return
					}

				}
				if (this.manualMax_gold != '' && this.manualMax_gold != '0' && this.manualMax_gold != 0) {
					if (!isMoney(this.manualMax_gold, "输入的最大金额不合法")) {
						return
					}
				}
				if (this.manualMax_gold != '' && this.manualMin_gold != '' &&  Number(this.manualMin_gold) > Number(this.manualMax_gold)) {
					// console.log(this.manualMin_gold,this.manualMax_gold)
					this.$message({type:'error',message:'最小金额不能大于最大金额'})
					return
				}
				this.manualDisCardList();
			},
			manualreferData(){
				this.manualMin_gold	=""
				this.manualMax_gold=""
				this.manualCard_name=""
				this.manualCard=""
				this.manualCarder_username=""
				this.manualpage=1
				this.manualDisCardList()
			},
			//查询右边卡列表
			choosedDisCardList(){
				let data ={
					page:this.manualRightpage,
					size:this.manualRightpageSize,
					order_id:this.item.order_id,
					water_no:this.item.water_no,
					card_order_id:this.item.card_order_id
				}
				this.$api.choosedDisCardList(data).then(res => {
					this.manualQueryloading = false;
					if (res.status == 1) {
						this.manualTableDataChoose = res.data.data
						this.manualRighttotal = res.data.total
					}
				}).catch(error => {
					this.manualQueryloading = false;
				})
			},
			//查询左边卡列表
			manualDisCardList() {
				let data = {
					business_no: this.item.business_no,
					carder_username: this.manualCarder_username,
					page: this.manualpage,
					size: this.manualpageSize,
					card: this.manualCard,
					card_name: this.manualCard_name,
					min_gold: this.manualMin_gold,
					max_gold: this.manualMax_gold
				}
				this.manualQueryloading = true;
				this.$api.manualDisCardList(data).then(res => {
					this.manualQueryloading = false;
					if (res.status == 1) {
						res.data.data.forEach(item => {
							item.payAmount = ''
							item.selected = false;
							for (let i = 0; i < this.manualTableDataChoose.length; i++) {
								if (item.id == this.manualTableDataChoose[i].id) {
									item.selected = true;
									item.payAmount = this.manualTableDataChoose[i].payAmount
								}
							}

						})
						this.manualTableData = res.data.data.filter(item => {
							if (!item.selected) {
								return item
							}
						});
						this.manualtotal = res.data.total
					}
				}).catch(error => {
					this.manualQueryloading = false;
				})
			},
			addChange(item) {
				// console.log(val)
				//console.log(item)
				let data = {
					id: item.id,
					type: 1,
					order_id: this.item.order_id,
					water_no: this.item.water_no,
					card_order_id: this.item.card_order_id,
				}
				this.manualloading = true
				this.$api.discardLock(data).then(res => {
					this.manualloading = false
					if (res.status == 1) {
						this.addManualData()
					} else {
						item.selected = false
						this.$message({type:'error',message:res.msg})
					}
				}).catch(error => {
					this.manualloading = false
					item.selected = false
				})
				// let addData = this.manualTableData.filter(item => {
				// 	if (item.selected) {
				// 		return item
				// 	}
				// })
				// if (addData.length > 0) {
				// 	this.canAdd = true
				// } else {
				// 	this.canAdd = false
				// }
			},
			cancleChange(item) {
				let data = {
					id: item.id,
					type: 2,
					order_id: this.item.order_id,
					water_no: this.item.water_no,
					card_order_id: this.item.card_order_id,
				}
				this.manualloading = true
				this.$api.discardLock(data).then(res => {
					this.manualloading = false
					if (res.status == 1) {
						this.cancleManualData()
					} else {
						this.$message({type:'error',message:res.msg})
						item.selected = false
					}

				}).catch(error => {
					this.manualloading = false
					item.selected = false
				})
				// let cancleData = this.manualTableDataChoose.filter(item => {
				// 	if (item.selected) {
				// 		return item
				// 	}
				// })
				// if (cancleData.length > 0) {
				// 	this.canCancle = true
				// } else {
				// 	this.canCancle = false
				// }
			},
			//添加到右边
			addManualData() {
				//右边
				let manualTableDataChoose = this.manualTableData.filter(item => {
					if (item.selected) {
						return item
					}
				})

				//左边				
				let manualTableData = this.manualTableData.filter(item => {
					if (!item.selected) {
						return item
					}
				});
				//清除右边选中
				manualTableDataChoose.forEach(item => {
					item.selected = false
				})
				this.manualTableData = manualTableData
				this.manualTableDataChoose.push(...manualTableDataChoose)
				this.canAdd = false
			},
			// 删除到左边
			cancleManualData() {
				//右边
				let manualTableDataChoose = this.manualTableDataChoose.filter(item => {
					if (!item.selected) {
						return item
					}
				})

				//左边				
				let manualTableData = this.manualTableDataChoose.filter(item => {
					if (item.selected) {
						return item
					}
				});
				//清除左边选中
				manualTableData.forEach(item => {
					item.selected = false
					item.payAmount = ''
				})
				this.manualTableData.push(...manualTableData)

				this.manualTableDataChoose = manualTableDataChoose
				this.canCancle = false
			},
			manualhandleSizeChange(val) {
				this.manualpageSize = val
				this.manualpage = 1
				this.manualDisCardList()
			},
			manualhandleCurrentChange(val) {
				this.manualpage = val
				this.manualDisCardList()
			},
			manualRighthandleSizeChange(val) {
				this.manualRightpageSize = val
				this.manualRightpage = 1
				this.choosedDisCardList()
			},
			manualRighthandleCurrentChange(val) {
				this.manualRightpage = val
				this.choosedDisCardList()
			},
			manualclose() {
				this.manualVisible = false;
				this.manualloading = false;
				this.manualQueryloading = false;
				this.canAdd = false;
				this.canCancle = false;
				this.manualTableData = [];
				this.manualTableDataChoose = [];
				this.manualtotal = 0;
				this.manualpage = 1;
				this.manualRighttotal = 0;
				this.manualRightpage = 1;
				this.item = {}
			},
			//手动派卡
			manualsuer() {
				let totalAmount = 0;
				let errorString = "";
				let pattern = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
				for (let i = 0; i < this.manualTableDataChoose.length; i++) {
					if (this.manualTableDataChoose[i].payAmount == '') {
						errorString = "请输入代付金额";
						break
					}
					if (!pattern.test(this.manualTableDataChoose[i].payAmount)) {
						errorString = "输入的代付金额不合法";
						break
					}
				}
				if (errorString != "") {
					this.$message({
						type: 'error',
						message: errorString
					})
					return
				}
				let dataArr = this.manualTableDataChoose.map(item => {
					totalAmount = Number(item.payAmount) + totalAmount;
					//money  bank_card_id
					return {
						money: item.payAmount,
						bank_card_id: item.id
					}
				})
				if (totalAmount != this.item.gold) {
					this.$message({
						type: 'error',
						message: '代付金额必须等于订单金额'
					})
					return
				}
				let data = {
					id: this.item.id,
					data: JSON.stringify(dataArr)
				}
				this.manualloading = true
				if (this.manualtype == 'child') {
					this.manualDisCard(data)
				} else {
					this.manualDisCardMain(data)
				}

				/* this.$api.manualDisCard(data).then(res=>{
					this.manualloading = false
					if(res.status == 1){
						this.manualclose();
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.$api.outInfo({id: this.tableData[this.pIndex].id}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
					}
					else{
						
					}
					
				}).catch(error=>{
					this.manualloading = false
				})
				console.log(data) */
			},
			//子订单手动选卡
			manualDisCard(data) {
				this.$api.manualDisCard(data).then(res => {
					this.manualloading = false
					if (res.status == 1) {
						this.manualclose();
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}

				}).catch(error => {
					this.manualloading = false
				})
			},
			//主订单手动选卡
			manualDisCardMain(data) {
				this.$api.manualDisCardMain(data).then(res => {
					this.manualloading = false
					if (res.status == 1) {
						this.manualclose();
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.$api.outInfo({
							id: this.tableData[this.pIndex].id
						}).then(subRes => {
							if (subRes.status == 1) {
								this.tableData.splice(this.pIndex, 1, subRes.data)
							} else {
								this.$message({
									type: 'error',
									message: subRes.msg
								})
							}
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}

				}).catch(error => {
					this.manualloading = false
				})
			}
		}
	};
</script>


<style scoped="scoped" lang="scss">
	@import '@/style/variables.scss';

	.transferTiete {
		width: calc(50% - 10px);
		text-align: center;
	}

	.transfer {
		height: 70vh;

		// width: 80%;
		.target,
		.source {
			border: solid 1px $tableBorderColor;
			width: calc(50% - 10px);
			// padding: 0 10px;
			height: 100%;

			.searchBox {
				height: 35px;
				width: 100%;
				display: flex;
				align-items: center;
				padding-left: 10px;
			}

			.sourceData {
				height: calc(100% - 35px - 42px);
			}

			.goldBox {
				height: 35px;
				line-height: 35px;
			}

			.targetData {
				height: calc(100% - 35px - 42px);
			}

			.manualPage {
				height: 32px;
			}
		}
	}
</style>
