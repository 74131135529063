import request from '@/util/request'
export default{
	//登录
	login(data){
		return request({url: '/agent/login/doLogin',method: 'post',data})		
	},	
	//登出
	logout(data){
		return request({url: '/agent/login/logout',method: 'post',data})		
	},	
	//谷歌验证检查
	validateGcode(data){
		return request({url: '/agent/login/validateGcode',method: 'post',data})		
	},
	//谷歌验证码绑定
	bingGoogle(data){
		return request({url: '/agent/login/bindGoogle',method: 'post',data})		
	},
	//订单状态
	stateList(data){
		return request({url: '/agent/order/stateList',method: 'post',data})		
	},
	//代付订单状态
	outStateList(data){
		return request({url: '/agent/order/outStateList',method: 'post',data,allowRepetition:true})		
	},
	//支付方式
	// $hasRepay = input('hasRepay', 1);//是否查询自动代付（支付类型） 1-是 2-否
	payType(data){
		return request({url: '/agent/order/payType',method: 'post',data})		
	},
	//订单列表
	orderInList(data){
		return request({url: '/agent/order/orderInList',method: 'post',data})		
	},
	//订单列表 统计
	orderInTotal(data){
		return request({url: '/agent/order/orderInTotal',method: 'post',data})		
	},
	
	//交易报表
	orderReport(data){
		return request({url: '/agent/order/report',method: 'post',data})		
	},
	//代付订单
	orderOutList(data){
		return request({url: '/agent/order/orderOutList',method: 'post',data})		
	},
	
	//修改账号密码
	/*  $username = input('username');
        $password = input('password');
        $code = input('code');// 谷歌验证码 */
	setPassword(data){
		return request({url: 'agent/user/setPassword',method: 'post',data})		
	},
	//修改代付密码
	/*  $username = input('username');
        $password = input('password');
        $code = input('code');// 谷歌验证码 */
	setPayPassword(data){
		return request({url: 'agent/user/setPayPassword',method: 'post',data})		
	},
	/* 修改公钥
	  $username = input('username');
        $key = input('key');
        $code = input('code');// 谷歌验证码*/
	setSignKey(data){
		return request({url: 'agent/user/setSignKey',method: 'post',data})		
	},
	/* 查询账号信息 
	 $username = input('username');
	 */
	userInfo(data){
		return request({url: 'agent/user/info',method: 'post',data,allowRepetition:true})		
	},
	//实时代付
	createOrder(data){
		return request({url: 'agent/order/create',method: 'post',data,allowRepetition:true})		
	},
	//主界面  统计数据
	commonTotal(data){
		return request({url: 'agent/order/commonTotal',method: 'post',data,allowRepetition:true})		
	},
	//http://api.jxkspys.com/manager/card/manualDisCardList 获取手动派卡列表的接口
	// $business_no = input('business_no');
	// $carder_id = input('carder_id');
	// $min_gold = input('min_gold', 0);
	// $max_gold = input('max_gold', 10000000);
	// $card = input('card');
	// $card_name = input('card_name');
	// $page = input('page', 1);
	// $size = input('size', Config::get('paginate.list_rows'));
	manualDisCardList(data){
		return request({url: '/agent/card/manualDisCardList',method: 'post',data})		
	},
	//  子订单手动派卡
	// {
	//     $id = input('id');
	//     $data = input('data');// money  bank_card_id
	manualDisCard(data){
		return request({url: '/agent/order/manualDisCard',method: 'post',data})		
	},
	//  主订单手动派卡
	// {
	//     $id = input('id');
	//     $data = input('data');// money  bank_card_id
	manualDisCardMain(data){
		return request({url: '/agent/order/manualDisCardMain',method: 'post',data})		
	},
	//平台提润
	  // $name = input('name');//持卡人姓名
	  //   $card = input('card');//卡号
	  //   $bank = input('bank');//银行名称
	  //   $money = input('money');//金额
	  //   $code = input('code');//谷歌验证
	mentionMoney(data){
		return request({url: '/agent/order/mentionMoney',method: 'post',data})		
	},
	
	//锁卡 解锁 银行卡 
	  //$id = input('id');
	  //$type = input('type'); //1-锁卡 2-解锁
	  
	  // $id = input('id');//卡ID
	  //         $order_id = input('order_id');
	  //         $water_no = input('water_no');
	  //         $card_order_id = input('card_order_id');
	  //         $type = input('type'); //1-锁卡 2-解锁
	discardLock(data){
		return request({url: '/agent/card/discardLock',method: 'post',data})		
	},
	
	//已选卡列表查询
	// $page = input('page', 1);
	//         $size = input('size', Config::get('paginate.list_rows'));
	//         $order_id = input('order_id');
	//         $water_no = input('water_no');
	//         $card_order_id = input('card_order_id');
	choosedDisCardList(data){
		return request({url: '/agent/card/choosedDisCardList',method: 'post',data})		
	},
	//提润订单 审核
	checkMentionOrder(data){
		return request({url: '/agent/order/checkMentionOrder',method: 'post',data})		
	},
	//商户列表
	traderList(data){
		return request({url: '/agent/order/traderList',method: 'post',data})		
	},
	//商户列表
	paywayList(data){
		return request({url: '/agent/order/paywayList',method: 'post',data})		
	},
	// 代理管辖下商户的各账户余额.
	traderCurrencyMoney(data){
		return request({url: '/agent/user/traderCurrencyMoney',method: 'post',data})		
	},
	// 代理管辖下商户的入金订单列表.
	offlineOrderList(data){
		return request({url: '/agent/order_offline/allList',method: 'post',data})		
	},
	// 获取所有币种.
	getCurrencys(data){
		return request({url: '/agent/order_offline/currency',method: 'post',data})		
	},
	// 汇率
	exchangeRate(data){
		return request({url: '/agent/info/getCurrencyExchangeRate',method: 'post',data,allowRepetition:true})	
	},
	userMoney(data){
		return request({url: '/agent/user/userMoney',method: 'post',data,allowRepetition:true})	
	},
	userMentionMoney(data){
		return request({url: '/agent/order/mentionMoney',method: 'post',data})		
	},
	//交易报表
	traderOutReport(data){
		return request({url: '/agent/order/traderOutReport',method: 'post',data})		
	},
	//代付报表
	reportOut(data){
		return request({url: '/agent/order/reportOut',method: 'post',data})		
	},
	
}