<template>
	<div>
        <el-table
            :data="trader_currencys"
            border
            style="width: 60%">
            <el-table-column
				align="center"
                label="商户名称">
                <template slot-scope="scope">
                    <span>{{scope.row.business_name}}</span>
                </template>
            </el-table-column>
            <el-table-column
				align="center"
                label="商户号">
                <template slot-scope="scope">
                    <span>{{scope.row.business_no}}</span>
                </template>
            </el-table-column>
            <el-table-column
				align="center"
                label="余额">
                <template slot-scope="scope">
                    
                        <span>{{scope.row.money}}</span>
                </template>
            </el-table-column>
        </el-table>
	</div>
</template>
<script>
	export default {
		data() {
			return {
                trader_currencys:[],
                timer:null
			}
        },
		created() {
            this.traderCurrencyMoney()
        },
        filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					return ((value * 1).toFixed(4) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
            traderCurrencyMoney() {
				this.$api.traderCurrencyMoney({}).then(res => {
					if (res.status == 1) {
						this.trader_currencys = res.data
					}
					for(let k = 0; k < this.trader_currencys.length; k++){
						console.log(k)
						console.log(this.trader_currencys[k]);
						for(let j=0;j<this.trader_currencys[k].currencys.length;j++){
							console.log(j)
							console.log(this.trader_currencys[k].currencys[j].money)
						}
					}
					
				})
			}
		}
	}
</script>
<style scoped="scoped" lang="scss">
	@import '@/style/variables.scss';
   .moneyFontSize{
	   font-size: 16px;
   }
   .usdtCss{
	   color: red;
	   font-size: xx-small;
   }
</style>