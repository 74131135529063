<template>
	<div id="app">
		<div class="topNav" :class="setBackgroundColor($PayNameImg)" v-if="!this.$route.meta.noNav">
			<div class="navbar flexX flexcenter">
				<div class="logo" @click="showDialog"><i class="el-icon-user-solid"></i> LOGO</div>
				<div style="padding: 0 15px;" @click="toggleClick">
					<div class="hamburger" :class="{'is-active':showSide==='hide'}">
						<div class="icon"></div>
					</div>
				</div>
			</div>
			
			<div class="flexX flexcenter">
				<!-- <div class="systemSet mr20" @click="gotoPageTirun">提润</div> -->
				<div class="systemSet mr20" @click="showDialog">设置</div>
				<span class="systemSet mr20" @click="validateGcode">绑定谷歌验证</span>
				<span class="mr20 username">账号：{{username}}</span>
				<el-button type="warning" @click="logout">退出登录</el-button>
			</div>
		</div>
		<div class="bottomMain" v-if="!this.$route.meta.noNav">
			<div class="sideBox" :class="[{sideHiden:showSide==='hide',sideShow:showSide==='show'}, setBackgroundColor($PayNameImg)]">
				<div v-for="(item,index) in nav" v-if="nav.length>0" :key="index" class="itemBox firstItemBox">
					<div class="navItem firstNav show pageNav" :class="{active:nowPath.includes(item.path) && item.meta.islink}" @click="gotopage(item,index,'showOne')">
						<span><span class="el-icon-menu"></span> {{item.meta.title}}</span>
						<span v-if="!item.meta.islink" :class="{'el-icon-arrow-right':!(show.showOne===index),'el-icon-arrow-down':(show.showOne === index)}"></span>
					</div>
					<div v-if="item.children && item.children.length > 0" class="childNav">
						<div v-for="(item2,index2) in item.children" :key="index2" class="itemBox">
							<div class="navItem twotNav pageNav" :class="{active:nowPath.includes(item2.path) && item2.meta.islink,ractive:show.showTwo===index2 && !item2.meta.islink,show:show.showOne === index}"
							 @click="gotopage(item2,index2,'showTwo')">
								<span>{{item2.meta.title}}</span>
								<span v-if="!item2.meta.islink" :class="{'el-icon-arrow-right':show.showTwo!=index2}"></span>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div class="threeDirectoryBox" :class="{noSide:showSide==='hide',hasSide:showSide==='show'}">
				<div v-if="threeDirectory.length > 0">
					<div class="threeChildNav">
						<div v-if="threeDirectory.length>0" v-for="(item3,index3) in threeDirectory" :key="index3" class="itemBox" :class="{active:nowPath===item3.path }">
							<div class="navItem pageNav" @click="gotopage(item3,index3,'showThree')">{{item3.meta.title}}</div>
						</div>
					</div>
				</div>
				<!-- <el-breadcrumb class="breadCrumb" separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item v-for="item in ParentDirector" v-if="ParentDirector.length>0">{{item.title}}</el-breadcrumb-item>
				</el-breadcrumb> -->
			</div>

			<div class="main-container" :class="{noSide:showSide==='hide',hasSide:showSide==='show',hasThreeDic:threeDirectory.length > 0}">
				<router-view />
			</div>
		</div>
		<div class="loginMain" v-if="this.$route.meta.noNav">
			<!-- 登录 -->
			<router-view />
		</div>

		<el-dialog center class="dialog" top="150px" title="" :visible.sync="dialogVisible" @close="close"
		 :close-on-click-modal="false">
			<div class="formBox" v-loading="dialogloading">
				<div>
					<el-tabs type="border-card" @tab-click="tabClick" v-model="activeName">
						<!-- <el-tab-pane label="代理信息" name='info'>
							<div class="flexX flexTop flexAround">
								<div>
									<el-form :label-position="labelPosition" :label-width="labelWidth">
										<el-form-item label="代理账号:">
											{{userInfo.username}}
										</el-form-item>
										<el-form-item label="代理类型:">
										</el-form-item>
										<el-form-item label="协议有效期:">
										</el-form-item>
										<el-form-item label="代理状态:">
											{{userInfo.status==0?'正常':'停用'}}
										</el-form-item>
									</el-form>
								</div>
								<div>
									<el-form :label-position="labelPosition" :label-width="labelWidth">
										<el-form-item label="代理名字:">
											{{userInfo.name}}
										</el-form-item>
										<el-form-item label="邮政编码:">
										</el-form-item>
										<el-form-item label="联系手机:">
										</el-form-item>
										<el-form-item label="代理地址:">
										</el-form-item>
									</el-form>
								</div>
							</div>
						</el-tab-pane> -->
						<el-tab-pane label="修改账号密码" name='password'>
							<el-form :label-position="labelPosition" :label-width="labelWidth">
								<!-- <el-form-item label="原密码:">
							    <el-input type="password" v-model="oldPassword"></el-input>
							  </el-form-item> -->
							  <el-form-item label="代理名字:">
							  	{{userInfo.name}}
							  </el-form-item>
							  <el-form-item label="代理账号:">
							  	{{userInfo.username}}
							  </el-form-item>
								<el-form-item label="新密码:">
									<el-input prefix-icon="el-icon-lock" type="password" placeholder="请输入密码" v-model="password"></el-input>
								</el-form-item>
								<el-form-item label="确认密码:">
									<el-input prefix-icon="el-icon-lock" type="password" placeholder="请再次输入密码" v-model="comPassword"></el-input>
								</el-form-item>
								<el-form-item label="谷歌验证码:">
									<div class="flexX">
										<el-input class="mr20" prefix-icon="el-icon-key" placeholder="请输入谷歌验证码" v-model="code"
										 clearable></el-input>
										<span class="cdanger inlineBlock whiteSpaceNowrap pointer" @click="validateGcode">绑定谷歌验证</span>
									</div>

								</el-form-item>

							</el-form>
						</el-tab-pane>
						</el-tabs>
				</div>
				<div class="textCenter">
					<el-button type="" @click="close">关闭</el-button>
					<el-button v-if="activeName!='info'" type="primary" @click="sure">确定</el-button>
				</div>
			</div>
			
		</el-dialog>
		
		
		<el-dialog center width="30%" title="绑定谷歌验证" :visible.sync="innerVisible" append-to-body @close="innerClose">
			<div class="qrcode" v-loading="innerloading">
				<!-- <img :src="qrcode" alt=""> -->
				<div class="box" v-if="JSON.stringify(googleData)!='{}'">
					<div class="mb20 textCenter"><b>第一步：请将用户名/密钥 复制到谷歌验证器中 或者 扫码下图二维码</b></div>
					<div class="box1">
						<div class="item flexX flexBetween flexcenter"><span><b>用户名:</b></span><span>{{googleData.username}}</span></div>
						<div class="item flexX flexBetween flexcenter"><span><b>密钥:</b></span><span>{{googleData.secret}}</span></div>
						<div class="item flexX flexBetween flexcenter"><span><b>密钥二维码:</b></span><img :src="googleData.qrCodeUrl" style="width: 100px;"></div>
					</div>
					<div class="mb20 mt20 textCenter"><b>第二步：输入谷歌验证器中的验证码</b></div>
					<div class="box1">
						<div class="flexX flexBetween flexcenter">
							<span><b>验证码:</b></span>
							<el-input style="width: 200px;" prefix-icon="el-icon-key" placeholder="请输入谷歌验证吗" v-model="code" clearable></el-input>
						</div>
					</div>
					<div class="mt20"><el-button type="primary" style="width: 100%;" @click="bingGoogle()">绑定密钥</el-button></div>
				</div>
				<!-- <div v-if="JSON.stringify(googleData) =='{}'">
					
				</div> -->
			</div>
			
		</el-dialog>
	</div>
</template>
<script>
	import local from './util/local.js'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isAllNumber
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		name: 'Home',
		components: {},
		data() {
			return {
				nav: [],
				show: {
					showOne: '',
					showTwo: '',
					showThree: ''
				},
				showSide: '', //是否显示左边导航
				nowPath: '',
				threeDirectory: [],
				ParentDirector: [],
				dialogVisible: false,
				dialogloading: false,
				innerVisible: false,
				innerloading: false,
				labelPosition: 'right',
				activeName: 'password',
				userInfo: {},
				googleData: {},
				password: '',
				comPassword: '',
				code: '',
				key: '',
				username: "",
				labelWidth:'100px',
				//代理提润
				// showTirun:false
			}
		},
		created() {
			this.getNav()
			this.nowPath = this.$route.path
			this.getTreeDirectoru()
			this.getBreadCrumb()
			this.username=local.get('dlusername')
		},
		mounted () {
			let beginTime = 0;//开始时间
			let differTime = 0;//时间差
			window.onunload = function (){
			  differTime = new Date().getTime() - beginTime;
			  if(differTime <= 3){
					window.localStorage.removeItem("dlusername");
					window.localStorage.removeItem("dltid");
			  };
			};
			window.onbeforeunload = function (){
			  beginTime = new Date().getTime();
			};
		},
		methods: {
			setBackgroundColor(name){
				if(name == 'wanding'){
					return "wanding"
				}else{
					return "default"
				}
			},
			getTreeDirectoru() {
				// console.log('dssssdddd', this.$route.path)
				for (let i = 0; i < this.nav.length; i++) {
					if (this.nav[i].children.length > 0) {
						for (let j = 0; j < this.nav[i].children.length; j++) {
							if (this.nowPath.includes(this.nav[i].children[j].path)) {
								if (this.nav[i].children[j].meta.leven === 1) {
									this.threeDirectory = this.nav[i].children[j].children
								}
							}
						}
					}
				}
				// console.log(this.threeDirectory,'=====')
			},
			getBreadCrumb() {
				for (let i = 0; i < this.nav.length; i++) {
					if (this.nowPath == this.nav[i].path && this.nav[i].meta.islink) {
						this.show.showOne = i
						this.ParentDirector = [{
							title: this.nav[i].meta.title,
							path: this.nav[i].path
						}]
						break
					} else {
						let navChild = this.nav[i].children;
						for (let j = 0; j < navChild.length; j++) {
							if (this.nowPath == navChild[j].path && navChild[j].children.length == 0) {
								this.show.showOne = i
								this.show.showTwo = j
								this.ParentDirector = [{
									title: this.nav[i].meta.title,
									path: this.nav[i].path
								}, {
									title: navChild[j].meta.title,
									path: navChild[j].path
								}]
								break
							} else {
								let threeChild = navChild[j].children;
								for (let k = 0; k < threeChild.length; k++) {
									if (this.nowPath == threeChild[k].path) {
										this.show.showOne = i
										this.show.showTwo = j
										this.ParentDirector = [{
											title: this.nav[i].meta.title,
											path: this.nav[i].path
										}, {
											title: navChild[j].meta.title,
											path: navChild[j].path
										}, {
											title: threeChild[k].meta.title,
											path: threeChild[k].path
										}]
										break
									}
								}
							}
						}
					}
				}
			},
			getNav() {
				let routes = this.$router.options.routes;
				let navAll = routes.filter(item => {
					if (item.directory) {
						return item
					}
				})
				let navArr = []
				for (let i = 0; i < navAll.length; i++) {
					if (navAll[i].meta.parentId === 0) {
						navAll[i].children = []
						navArr.push(navAll[i])
						navAll.splice(i, 1)
						i = i - 1
					}
				}
				for (let i = 0; i < navAll.length; i++) {
					if (navAll[i].meta.parentId != 0 && navAll[i].meta.leven === 1) {
						for (let j = 0; j < navArr.length; j++) {
							if (navAll[i].meta.parentId === navArr[j].meta.id) {
								navAll[i].children = []
								navArr[j].children.push(navAll[i])
							}
						}
					}
				}
				// 三级目录
				for (let i = 0; i < navAll.length; i++) {
					if (navAll[i].meta.parentId != 0 && navAll[i].meta.leven === 2) {
						for (let j = 0; j < navArr.length; j++) {
							for (let k = 0; k < navArr[j].children.length; k++) {
								if (navAll[i].meta.parentId === navArr[j].children[k].meta.id) {
									navArr[j].children[k].children.push(navAll[i])
								}
							}
						}
					}
				}
				this.nav = navArr
				// console.log(navAll, navArr)
			},
			gotopage(item, index, showNum) {
				if (this.showSide == 'show') {
					this.showSide = ""
				}
				this.showChildren(index, showNum, item.meta.islink)
				if (this.$route.path === item.path && item.meta.islink) {
					this.$router.push({
						path: '/replace'
					})
					return
				}
				if (item.meta.islink) {
					this.$router.push(item.path)
				}
				if (item.meta.leven === 1) {
					this.threeDirectory = item.children
				}
				// console.log('=====+++++======',this.$router)
			},
			toggleClick() {
				if (this.showSide === 'hide') {
					this.showSide = 'show'
				} else {
					this.showSide = 'hide'
				}
			},
			showChildren(index, showNum, islink) {
				if (showNum === 'showOne') {
					this.show.showTwo = ''
				}
				if (this.show[showNum] === index) {
					if (!islink) {
						this.show[showNum] = ''
					}
				} else {
					this.show[showNum] = index
				}
			},
			logout() {
				// this.$local.remove()
				this.$confirm('确认退出?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						username: this.$local.get('dlusername')
					}
					this.$api.logout(data).then(res => {
						// console.log(res)
						if (res.status == 1) {
							this.$local.remove('dltid')
							this.$local.remove('dlusername')
							this.$router.push('/')
						} else {
							this.$message({
								type: 'info',
								message: res.msg
							});
						}

					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});


			},
			validateGcode() {
				this.innerVisible = true;
				let data = {
					username: local.get('dlusername'),
				}
				// this.innerloading = true
				this.$api.validateGcode(data).then(res => {
					this.innerloading = false
					// console.log(res)
					//1 成功   2未登录  其他失败
					if (res.status === 1) {						
						this.googleData = res.data
					}
					 else if(res.status == 3){
					 	// this.googleCode = false
						this.innerVisible = false;
					 	this.$message({
					 	  type: 'info',
					 	  message: res.msg
					 	});    
					 }
					 else {
						this.$message({
							type: 'info',
							message: res.msg
						});
					}
				}).catch(error => {
					this.innerloading = false
				})
			},
			innerClose(){
				this.innerVisible = false
				this.googleData = {}
				this.code = ''
			},
			bingGoogle(){
				if(isEmpty(this.code,'请输入谷歌验证码')){
					return
				}
				let data = {
					username:this.googleData.username,
					code:this.code
				}
				this.$api.bingGoogle(data).then(res=>{
					if(res.status == 1){
						this.$message({
						  type: 'success',
						  message: res.msg
						});
						this.innerVisible = false
						this.googleData = {}
						this.code = ''
					}else{
						this.$message({
						  type: 'error',
						  message: res.msg
						});   
					}
				})
			},
			showDialog() {
				this.dialogVisible = true
				// if (JSON.stringify(this.userInfo) == '{}') {
					this.getInfo()
				// }
			},
			tabClick(val) {
				this.password = '';
				this.comPassword = '';
				this.code = '';
				// this.key = '';
				this.username=local.get('dlusername')
				if (val.name == 'info') {
					//查看商户信息
				}
				if (val.name == 'password') {
					//修改账号信息
				}
				if (val.name == 'payPassword') {
					//修改代付密码
				}
				if (val.name == 'signKey') {
					//密钥维护
					let userInfo = local.get("userInfo")
					if(userInfo){
						this.key = userInfo.google_key
					}
				}
			},
			//查询基本信息
			getInfo() {
				// this.dialogloading = true
				this.$api.userInfo({}).then(res => {
					this.dialogloading = false;
					if (res.status == 1) {
						this.userInfo = res.data
						this.key = this.userInfo.md5_key
						local.set("userInfo",this.userInfo)
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			close() {
				this.dialogVisible = false
				this.password = '';
				this.comPassword = '';
				this.code = '';
				// this.key = '';
				this.activeName = 'password'
			},
			sure() {
				console.log(this.activeName)
				if (this.activeName == 'password'||this.activeName == 'payPassword') {
					if (isEmpty(this.password, '请输入新密码')) {
						return
					}
					if (this.password != this.comPassword) {
						this.$message({
							type: 'error',
							message: '两次输入密码不一致'
						})
						return
					}
				}
				if (this.activeName == 'signKey'){
					if (isEmpty(this.key, '请输入MD5密钥')) {
						return
					}
				}
				if (isEmpty(this.code, '请输入谷歌验证码')) {
					return
				}
				if (!isAllNumber(this.code, '谷歌验证码只能是数字')) {
					this.$message({
						type: 'error',
						message: '谷歌验证码只能是数字'
					})
					return
				}
				let data = {
					
					code: this.code,
				}
				if (this.activeName == 'password'||this.activeName == 'payPassword') {
					data.password= this.$md5(this.password)
				}else if (this.activeName == 'signKey'){
					data.key= this.key
				}
				this.dialogloading = true
				if (this.activeName == 'password') {
					this.$api.setPassword(data).then(res => {
						this.dialogloading = false
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.password = '';
							this.comPassword = '';
							this.code = '';
							// this.key = '';
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				} else if (this.activeName == 'payPassword') {
					this.$api.setPayPassword(data).then(res => {
						this.dialogloading = false
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.password = '';
							this.comPassword = '';
							this.code = '';
							// this.key = '';
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				} else if (this.activeName == 'signKey') {
					this.$api.setSignKey(data).then(res => {
						this.dialogloading = false
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.password = '';
							this.comPassword = '';
							this.code = '';
							this.getInfo()
							// this.key = '';
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}
			},
			//代理提润
			gotoPageTirun(){
				this.$router.push({path:'/Tirun'})
				if (this.$route.path === '/Tirun') {
					this.$router.push({path:'/replace'})
				}
			},
		},
		watch: {
			$route(to, from) {
				this.username=local.get('dlusername')
				this.nowPath = this.$route.path
				this.getBreadCrumb()
				this.getTreeDirectoru()
				
				
			}
		}
	}
</script>
<style lang="scss">
	@import '@/style/variables.scss';

	* {
		padding: 0;
		margin: 0;
	}
	
	.default{
		background: #AA076B;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to bottom, #61045F, #AA076B);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to bottom, #61045F, #AA076B); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	}
	.wanding{
		background: #16222A;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to bottom, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to bottom, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	}

	#app {
		// font-family: Avenir, Helvetica, Arial, sans-serif;
		font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// text-align: center;
		color: #303133;
		font-size: 14px;
	}

	#nav {
		padding: 30px;
	}

	.topNav {
		height: 60px;
		background-color: $menuBg;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 99;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 20px;
		.username{
			color:$navColor
		}
		.systemSet{
			color:$navColor;
			cursor: pointer;
			
		}
	}

	.hamburger {
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		height: 20px;
	}

	.hamburger .icon {
		background-color: transparent;
		border-bottom: 20px solid $warning;
		border-right: 20px solid transparent;
		transform: rotate(45deg);
	}

	.hideSidebar .hamburger .icon {
		background-color: transparent;
		border-bottom: 20px solid $warning;
		border-right: 20px solid transparent;
		transform: rotate(-135deg);
	}

	.hamburger.is-active {
		transform: rotate(180deg);
	}

	.sideBox {
		color: $navColor;
		text-align: left;


		.itemBox {
			padding-left: 20px;
			cursor: pointer;



			&.firstItemBox {
				padding-left: 0;

			}

			.childNav {
				// background-color: $subMenuBg;
				// font-size: 18px;
			}

			.navItem {
				padding: 12px 10px;
				display: none;

				align-items: center;
				justify-content: space-between;

				&.twotNav {
					font-size: 16px;
					color: $navSubColor;
					padding-left: 20px;
				}

				&.firstNav {
					border-bottom: 1px solid rgba(107, 108, 109, 0.19);

				}

				&.show {
					display: flex;
				}

				&.active {
					color: $warning;
				}

				&.ractive {
					color: #f5f5f5;
				}
			}
		}

	}

	.threeDirectoryBox {
		.threeChildNav {
			.itemBox {
				// margin-right: 20px;
				padding: 0 10px;
				height: 100%;
				display: flex;
				align-items: center;
				border-bottom: solid 2px rgba(0, 0, 0, 0);
				cursor: pointer;

				&.active {
					color: $primary;
					background: #fff;
					border-left: solid 1px $tableBorderColor;
					border-right: solid 1px $tableBorderColor;
				}

				&:first-child.active {
					border-left: none
				}
			}
		}
	}

	.activeClass {
		color: $warning;
	}

	.loginMain {
		min-height: 100vh;
	}

	.qrcode {
		// display: inline-block;
		width: 100%;
		height: 100%;
		text-align: center;

		img {
			max-width: 100%;
			background-color: #fff; //设置白色背景色
			box-sizing: border-box;
		}
	}
	.box{
		width: 500px;
		margin: 0 auto;
		// background-color: $pageColor;
		.box1{			
			background-color: #fff;
			padding: 20px;
			width: 100%;
			.item{
				border-bottom: solid 1px $tableBorderColor;
				padding: 10px 0;
			}
		}
		
	}
</style>
